<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.6667 8.75001V5.66667C16.6667 4.26654 16.6667 3.56647 16.3942 3.0317C16.1545 2.56129 15.7721 2.17884 15.3017 1.93916C14.7669 1.66667 14.0668 1.66667 12.6667 1.66667H7.33337C5.93324 1.66667 5.23318 1.66667 4.6984 1.93916C4.22799 2.17884 3.84554 2.56129 3.60586 3.0317C3.33337 3.56647 3.33337 4.26654 3.33337 5.66667V14.3333C3.33337 15.7335 3.33337 16.4335 3.60586 16.9683C3.84554 17.4387 4.22799 17.8212 4.6984 18.0609C5.23318 18.3333 5.93324 18.3333 7.33337 18.3333H10M11.6667 9.16667H6.66671M8.33337 12.5H6.66671M13.3334 5.83334H6.66671M15 17.5V12.5M12.5 15H17.5" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
export default {
  name: 'IconFilePlus',
}
</script>
